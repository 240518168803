<template>
    <div class="event-wrapper">
      <CommonBlock
          title="Рекомендації та ініціативи"
          :visible-back-btn="true"
          :breadcrumbs="breadcrumbs"
          :cancelBackBtnEventPassed="true"
          class="event-common calendar-common"
      >
          <div class="event-wrapper__slot">
              <div class="calendar-container">
                  <v-calendar
                      locale="uk"
                      title-position="left"
                      :attributes="attrs"
                      @dayclick='eventsUrl'
                      @update:from-page="pageChange"
                      @update:to-page="pageChange"
                  />
              </div>
              <div class="calendar-filter">
                  <div>
                      <input @click="filterEvents(allCategories[0])" type="checkbox" id="meetings" value="Вуличні акції/мітинги" v-model="checkedFilters">
                      <label for="meetings">Вуличні акції/мітинги</label>
                      <svg class="calendar-filter_icon calendar-filter_icon-meetings" width="40" height="24" viewBox="0 0 40 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="24" rx="12" fill="#F40035"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8053 6.44662C26.0417 6.16275 26.3893 6 26.7585 6C27.4434 6 28 6.55665 28 7.24152V16.7584C28 17.4433 27.4434 17.9999 26.7585 18C26.3894 18 26.0417 17.8372 25.8054 17.5534L25.2709 16.9121C24.7787 16.3216 24.1036 15.7435 23.3008 15.2226C23.1074 16.4179 22.0684 17.3333 20.8197 17.3333H17.1804C15.7943 17.3333 14.6667 16.2057 14.6667 14.8196V14.5136C14.6667 14.097 14.7695 13.6933 14.9629 13.3333H14.3333C14.1497 13.3333 14 13.4831 14 13.6666C14 14.2181 13.5514 14.6666 13 14.6666C12.4486 14.6666 12 14.2181 12 13.6666V10.3333C12 9.78189 12.4486 9.33333 13 9.33333C13.5514 9.33333 14 9.78189 14 10.3333C14 10.5169 14.1497 10.6667 14.3333 10.6667H19.0534C20.8066 10.6667 23.2363 9.52864 24.3594 8.18099L25.8053 6.44662ZM20.8197 15.3333C21.1029 15.3333 21.3333 15.1028 21.3333 14.8196V14.5136C21.3333 14.2304 21.1029 13.9999 20.8197 13.9999H17.1803C16.8971 13.9999 16.6667 14.2304 16.6667 14.5136V14.8196C16.6667 15.1028 16.8971 15.3333 17.1803 15.3333H20.8197Z" fill="white"/>
                      </svg>
                      <span>{{allCategories[0]}}</span>
                  </div>
                  <div>
                      <input @click="filterEvents(allCategories[1])" type="checkbox" id="advicesForEvents" value="Рекомендації до проведення свят/заходів" v-model="checkedFilters">
                      <label for="advicesForEvents" >Рекомендації до проведення свят/заходів</label>
                      <svg class="calendar-filter_icon calendar-filter_icon-advicesForEvents" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="24" rx="12" fill="#18B4F8"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4601 15.4947L21.8853 6.13582C21.4638 5.4247 20.7195 5 19.8942 5C19.069 5 18.3246 5.4247 17.9032 6.13582L12.3288 15.4947C11.8983 16.2208 11.8902 17.0928 12.3066 17.827C12.724 18.5617 13.476 19 14.3194 19H25.4691C26.3124 19 27.0645 18.5617 27.4818 17.827C27.8983 17.0928 27.8902 16.2208 27.4601 15.4947ZM19.8943 7.86132C20.4057 7.86132 20.8203 8.27687 20.8203 8.78953V12.5024C20.8203 13.015 20.4057 13.4307 19.8943 13.4307C19.3828 13.4307 18.9682 13.015 18.9682 12.5024V8.78953C18.9681 8.27687 19.3828 7.86132 19.8943 7.86132ZM18.5051 15.7512C18.5051 16.519 19.1282 17.1435 19.8943 17.1435C20.6603 17.1435 21.2834 16.519 21.2834 15.7512C21.2834 14.9834 20.6603 14.3588 19.8943 14.3588C19.1282 14.3588 18.5051 14.9834 18.5051 15.7512Z" fill="white"/>
                      </svg>
                      <span>{{allCategories[1]}}</span>

                  </div>
                  <div>
                      <input @click="filterEvents(allCategories[2])" type="checkbox" id="localIniciatives" value="Місцеві ініціативи" v-model="checkedFilters">
                      <label for="localIniciatives">Місцеві ініціативи</label>
                      <svg class="calendar-filter_icon calendar-filter_icon-localIniciatives" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="24" rx="12" fill="#A7DA2A"/>
                          <path d="M19.5625 18.3333H19.0807C18.8775 17.5735 17.9938 17 16.9375 17H16.0625C15.0062 17 14.1225 17.5735 13.9193 18.3333H13.4375C13.1957 18.3333 13 18.4824 13 18.6667C13 18.8509 13.1957 19 13.4375 19H19.5625C19.8043 19 20 18.8509 20 18.6667C20 18.4824 19.8043 18.3333 19.5625 18.3333Z" fill="white"/>
                          <path d="M25.7734 5.77729C25.6348 5.70952 25.4707 5.72854 25.35 5.82604C24.2867 6.68913 22.9133 6.68913 21.85 5.82604C20.6905 4.88572 19.2466 4.75164 18 5.40624V12.0651C19.0406 11.3163 20.3351 11.3444 21.35 12.1682C22.0336 12.7226 22.8168 13 23.6 13C24.3832 13 25.1664 12.7226 25.85 12.1682C25.9449 12.0909 26 11.9744 26 11.8512V6.14307C26 5.98695 25.9117 5.84506 25.7734 5.77729Z" fill="white"/>
                          <path d="M16 5C15.4477 5 15 5.37689 15 5.84184V16H17V5.84184C17 5.37689 16.5523 5 16 5Z" fill="white"/>
                      </svg>
                      <span>{{allCategories[2]}}</span>

                  </div>
                  <div>
                      <input @click="filterEvents(allCategories[3])" type="checkbox" id="communityEvents" value="Партійні заходи" v-model="checkedFilters">
                      <label for="communityEvents">Партійні заходи</label>
                      <svg class="calendar-filter_icon calendar-filter_icon-communityEvents" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="40" height="24" rx="12" fill="#B601D9"/>
                          <ellipse cx="19.9265" cy="7.67647" rx="2.77941" ry="2.67647" fill="white"/>
                          <circle cx="25.4853" cy="8.80859" r="1.75" fill="white"/>
                          <circle cx="14.5735" cy="8.80859" r="1.75" fill="white"/>
                          <path d="M16.1176 12.0677C15.4533 11.5189 14.8517 11.5916 14.0835 11.5916C12.9347 11.5916 12 12.5284 12 13.6797V17.0588C12 17.5588 12.4047 17.9653 12.9025 17.9653C15.0512 17.9653 14.7924 18.0045 14.7924 17.8719C14.7924 15.4775 14.5111 13.7216 16.1176 12.0677Z" fill="white"/>
                          <path d="M20.6787 11.6249C19.3166 11.512 18.1326 11.6262 17.1114 12.4635C15.4024 13.8232 15.7313 15.6539 15.7313 17.8936C15.7313 18.4862 16.2166 18.9773 16.8223 18.9773C23.3982 18.9773 23.6599 19.188 24.0498 18.3302C24.1777 18.0402 24.1427 18.1323 24.1427 15.3574C24.1427 13.1534 22.2213 11.6249 20.6787 11.6249Z" fill="white"/>
                          <path d="M25.9753 11.5915C25.2029 11.5915 24.6046 11.5196 23.9412 12.0677C25.5357 13.7092 25.2665 15.3453 25.2665 17.8717C25.2665 18.0051 25.0516 17.9651 27.1241 17.9651C27.6396 17.9651 28.0588 17.544 28.0588 17.0264V13.6796C28.0588 12.5283 27.1241 11.5915 25.9753 11.5915Z" fill="white"/>
                      </svg>
                      <span>{{allCategories[3]}}</span>

                  </div>
              </div>
          </div>
      </CommonBlock>
     <div class="content">
        <div class="content-top">
            <h3 class="content-title">Партійні заходи</h3>
            <h5 class="content-subtitle">{{selectedEvent.category}}</h5>
        </div>

        <div class="event">

            <div class="event-info">
                <svg v-if="allCategories[0] == selectedEvent.category" class="category-icon event-info_item" width="40" height="24" viewBox="0 0 40 24" fill="none"  xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="24" rx="12" fill="#F40035"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8053 6.44662C26.0417 6.16275 26.3893 6 26.7585 6C27.4434 6 28 6.55665 28 7.24152V16.7584C28 17.4433 27.4434 17.9999 26.7585 18C26.3894 18 26.0417 17.8372 25.8054 17.5534L25.2709 16.9121C24.7787 16.3216 24.1036 15.7435 23.3008 15.2226C23.1074 16.4179 22.0684 17.3333 20.8197 17.3333H17.1804C15.7943 17.3333 14.6667 16.2057 14.6667 14.8196V14.5136C14.6667 14.097 14.7695 13.6933 14.9629 13.3333H14.3333C14.1497 13.3333 14 13.4831 14 13.6666C14 14.2181 13.5514 14.6666 13 14.6666C12.4486 14.6666 12 14.2181 12 13.6666V10.3333C12 9.78189 12.4486 9.33333 13 9.33333C13.5514 9.33333 14 9.78189 14 10.3333C14 10.5169 14.1497 10.6667 14.3333 10.6667H19.0534C20.8066 10.6667 23.2363 9.52864 24.3594 8.18099L25.8053 6.44662ZM20.8197 15.3333C21.1029 15.3333 21.3333 15.1028 21.3333 14.8196V14.5136C21.3333 14.2304 21.1029 13.9999 20.8197 13.9999H17.1803C16.8971 13.9999 16.6667 14.2304 16.6667 14.5136V14.8196C16.6667 15.1028 16.8971 15.3333 17.1803 15.3333H20.8197Z" fill="white"/>
                </svg>
                 <svg v-if="allCategories[1] == selectedEvent.category" class="category-icon event-info_item" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="24" rx="12" fill="#18B4F8"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4601 15.4947L21.8853 6.13582C21.4638 5.4247 20.7195 5 19.8942 5C19.069 5 18.3246 5.4247 17.9032 6.13582L12.3288 15.4947C11.8983 16.2208 11.8902 17.0928 12.3066 17.827C12.724 18.5617 13.476 19 14.3194 19H25.4691C26.3124 19 27.0645 18.5617 27.4818 17.827C27.8983 17.0928 27.8902 16.2208 27.4601 15.4947ZM19.8943 7.86132C20.4057 7.86132 20.8203 8.27687 20.8203 8.78953V12.5024C20.8203 13.015 20.4057 13.4307 19.8943 13.4307C19.3828 13.4307 18.9682 13.015 18.9682 12.5024V8.78953C18.9681 8.27687 19.3828 7.86132 19.8943 7.86132ZM18.5051 15.7512C18.5051 16.519 19.1282 17.1435 19.8943 17.1435C20.6603 17.1435 21.2834 16.519 21.2834 15.7512C21.2834 14.9834 20.6603 14.3588 19.8943 14.3588C19.1282 14.3588 18.5051 14.9834 18.5051 15.7512Z" fill="white"/>
                </svg>
                <svg v-if="allCategories[2] == selectedEvent.category" class="category-icon event-info_item" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="24" rx="12" fill="#A7DA2A"/>
                            <path d="M19.5625 18.3333H19.0807C18.8775 17.5735 17.9938 17 16.9375 17H16.0625C15.0062 17 14.1225 17.5735 13.9193 18.3333H13.4375C13.1957 18.3333 13 18.4824 13 18.6667C13 18.8509 13.1957 19 13.4375 19H19.5625C19.8043 19 20 18.8509 20 18.6667C20 18.4824 19.8043 18.3333 19.5625 18.3333Z" fill="white"/>
                            <path d="M25.7734 5.77729C25.6348 5.70952 25.4707 5.72854 25.35 5.82604C24.2867 6.68913 22.9133 6.68913 21.85 5.82604C20.6905 4.88572 19.2466 4.75164 18 5.40624V12.0651C19.0406 11.3163 20.3351 11.3444 21.35 12.1682C22.0336 12.7226 22.8168 13 23.6 13C24.3832 13 25.1664 12.7226 25.85 12.1682C25.9449 12.0909 26 11.9744 26 11.8512V6.14307C26 5.98695 25.9117 5.84506 25.7734 5.77729Z" fill="white"/>
                            <path d="M16 5C15.4477 5 15 5.37689 15 5.84184V16H17V5.84184C17 5.37689 16.5523 5 16 5Z" fill="white"/>
                </svg>
                <svg v-if="allCategories[3] == selectedEvent.category" class="category-icon event-info_item" width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="24" rx="12" fill="#B601D9"/>
                            <ellipse cx="19.9265" cy="7.67647" rx="2.77941" ry="2.67647" fill="white"/>
                            <circle cx="25.4853" cy="8.80859" r="1.75" fill="white"/>
                            <circle cx="14.5735" cy="8.80859" r="1.75" fill="white"/>
                            <path d="M16.1176 12.0677C15.4533 11.5189 14.8517 11.5916 14.0835 11.5916C12.9347 11.5916 12 12.5284 12 13.6797V17.0588C12 17.5588 12.4047 17.9653 12.9025 17.9653C15.0512 17.9653 14.7924 18.0045 14.7924 17.8719C14.7924 15.4775 14.5111 13.7216 16.1176 12.0677Z" fill="white"/>
                            <path d="M20.6787 11.6249C19.3166 11.512 18.1326 11.6262 17.1114 12.4635C15.4024 13.8232 15.7313 15.6539 15.7313 17.8936C15.7313 18.4862 16.2166 18.9773 16.8223 18.9773C23.3982 18.9773 23.6599 19.188 24.0498 18.3302C24.1777 18.0402 24.1427 18.1323 24.1427 15.3574C24.1427 13.1534 22.2213 11.6249 20.6787 11.6249Z" fill="white"/>
                            <path d="M25.9753 11.5915C25.2029 11.5915 24.6046 11.5196 23.9412 12.0677C25.5357 13.7092 25.2665 15.3453 25.2665 17.8717C25.2665 18.0051 25.0516 17.9651 27.1241 17.9651C27.6396 17.9651 28.0588 17.544 28.0588 17.0264V13.6796C28.0588 12.5283 27.1241 11.5915 25.9753 11.5915Z" fill="white"/>
                </svg>
                <div class="calendar event-info_item" >
                    <span class="calendar-date">{{`${selectedEvent.date.slice(8, )}/${selectedEvent.date.slice(5, 7)}`}}</span>
                    <span class="calendar-day">{{selectedEvent.weekDay}}</span>
                </div>
                <div class="time event-info_item" >
                    <span class="time-start">{{selectedEvent.start_time.slice(0, 5)}}</span>
                    <span class="time-finish">{{selectedEvent.end_time.slice(0, 5)}}</span>
                </div>
            </div>
            <div>
                <img v-if="selectedEvent.photo" :src="selectedEvent.photo" class="calendar-event_img" align="right" alt="people"/>
                 <h4 class="event-title">{{selectedEvent.title}}</h4>
                 <p class="calendar-event_text" v-html="selectedEvent.text"></p>
            </div>


            <router-link to="/events" class="btn">
                 <CustomButton label="Усі заходи"/>
            </router-link>


        </div>

     </div>
    </div>
</template>

<script>
import CommonBlock from "../components/CommonBlock";
import CustomButton from "@/components/CustomButton";
import EventService from "../services/EventService";

export default {
  name: 'event',
  components: {
      CommonBlock,
      CustomButton
  },
  data() {
    return {

      monthEvents: [],
      breadcrumbs: [
        {id: 1, name: 'Рекомендації та ініціативи', link: '/events'},
        {id: 2, name: 'Подія'}

      ],
      checkedFilters: ["Вуличні акції/мітинги", "Рекомендації до проведення свят/заходів", "Місцеві ініціативи", "Партійні заходи"],
      allCategories: ["Вуличні акції/мітинги", "Рекомендації до проведення свят/заходів", "Місцеві ініціативи", "Партійні заходи"],
      selectedEvent: {
        category: '',
        date: '',
        end_time: '',
        id: '',
        start_time: '',
        text: '',
        title: '',
        photo: ''
        },
        selectedMonth: null,
        selectedDate: new Date()
    };
  },
  computed: {
    attrs: function() {
        return [
            {
                key: 'today',
                highlight: {
                    color: 'yellow',
                    fillMode: 'solid',
                    class: 'highlight'
                },
                dates: new Date(),
            },
            {
                dot: {
                    backgroundColor: '#ffc900',
                },
                dates: this.getDotAttrs(),
            },
        ]
    },
    selectedDate: function() {
        this.selectedDate = new Date(this.selectedEvent.date)
    },
  },
  async created() {
    const id = this.$route.params.id
    const currentEvent = await EventService.getEvent(id);
    this.selectedEvent = currentEvent

    const days = ['Недiля', 'Понедiлoк', 'Вiвторок', 'Середа', 'Четвер', 'Пятница', 'Субота'];
    this.selectedEvent.weekDay =  days[new Date(this.selectedEvent.date).getDay()]

    await this.getMonthEvents();
  },
    watch: {
        selectedMonth: function() {
            this.getMonthEvents()
        },
    },
  methods: {
    dayClicked(day) {
        this.selectedDate = day.date;
    },
    async getMonthEvents() {
        this.monthEvents = []
        let date;
        if (this.selectedMonth) {
            date = new Date(this.selectedMonth.year, this.selectedMonth.month-1)
        } else {
            date = new Date()
        }

        const zero = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '0')
        const first = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '1')
        const second = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '2')
        const third = await EventService.getMonth(date.getMonth() + 1, date.getFullYear(), '3')

        if (this.checkedFilters.includes(this.allCategories[0])) {
            this.monthEvents.push(...zero)
        }
            if (this.checkedFilters.includes(this.allCategories[1])) {
            this.monthEvents.push(...first)
        }
            if (this.checkedFilters.includes(this.allCategories[2])) {
            this.monthEvents.push(...second)
        }
            if (this.checkedFilters.includes(this.allCategories[3])) {
            this.monthEvents.push(...third)
        }
    },
    getDotAttrs() {
        const array = []
        let date
        if (this.selectedMonth) {
            date = new Date(this.selectedMonth.year, this.selectedMonth.month - 1)
        } else {
            date = new Date(this.selectedDate)
        }
        this.monthEvents.forEach(ev => {
            array.push(date.setDate(ev))
        })
        return array
    },
    pageChange(page) {
        this.selectedMonth = page
    },
    eventsUrl(day) {
        this.$router.data = day
        this.$router.push(`/events`)
    },
    async filterEvents() {
        this.getMonthEvents()
    },
  },
}
</script>

<style lang="less" scoped>

    .calendar-container {
        @media (max-width: 1100px) {
            margin-left: -10px;
        }
    }

    .event-wrapper {
        display: flex;

        @media (max-width: 980px) {
            display: block;
        }
    }
    .content {
        padding: 80px 80px 80px 80px;
        width: 70%;
        height: 100vh;
        overflow-y: auto;

        &-top {
          padding-bottom: 18px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          margin-bottom: 20px;
        }
        &-title {
            font-weight: bold;
            font-size: 30px;
            line-height: 40px;
            color: #1A2744;
            margin-bottom: 12px;
        }
        &-subtitle {
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -0.14px;
            color: #1A2744;
        }

        @media (max-width: 1100px) {
            padding: 70px 50px 50px;
        }
        @media (max-width: 980px) {
            width: 100%;
            padding: 70px 50px 50px;
            height: initial;
        }
        @media (max-width: 700px) {
            padding: 30px;
        }
    }

    .event {
        &-title {
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.168px;
            color: #1A2744;
            margin-bottom: 20px;
        }
        p {
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: -0.14px;
            color: #1A2744;
            margin-bottom: 20px;
        }
    }

    .event-info {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    .calendar {
        margin-right: 45px;
        position: relative;
        &-date {
            font-weight: 900;
            font-size: 20px;
            color: #1A2744;
            display: block;
        }
        &-day {
            font-weight: normal;
            font-size: 14px;
            color: #1A2744;
        }
        &-event_img {
            max-width: 480px;
            height: 256px;
            margin-left: 20px;
            margin-bottom: 28px;

            @media (max-width: 1100px) {
                width: 100%;
                max-width: 100%;
                height: initial;
                margin-left: 0;
            }
        }
        &:after {
            position: absolute;
            content: '';
            top: 15px;
            left: -26px;
            width: 15px;
            height: 17px;
            background: url(../assets/images/calendar.svg) no-repeat center center;
            background-size: contain;
        }
    }
    .calendar-filter {
            display: flex;
            flex-direction: column;
            color: #ffc900;

            &  div {
                display: flex;
                margin-bottom: 25px;
                position: relative;

                & span {
                    color: white;
                    font-size: 0.8em;
                    position: absolute;
                    left: 120px;
                    width: fit-content;

                    &::before {
                        content: '';
                        height: 1px;
                        width: 10px;
                        background-color: #fff;
                        display: block;
                        position: absolute;
                        left: -15px;
                        top: 7px;
                    }
                }
            }
            & input[type='checkbox'] {
                opacity: 0;
                cursor: pointer;
            }
            & input[type='checkbox'] + label{
                width: 16px;
                height: 16px;
                display: block;
                border-radius: 4px;
                position: relative;
                margin-left: 20px;
                text-indent: -999px;
                border: 1px solid #ffc900;
                cursor: pointer;
            }
            & input[type='checkbox']:checked + label {
                border: 1px solid #ffc900;
                background: #ffc900;
            }
            &_icon {
                height: 24px;
                top: -4px;
                left: 60px;
                width: 40px;
                position: absolute;
                display: block;
                text-transform: none;

                }

            @media screen and (max-width: 1100px) {
                input[type=checkbox] {
                    display: none;

                    & + label {
                        margin-left: 0;
                    }
                }

                &_icon {
                    left: 25px;
                }

                div span {
                    left: 85px;
                    max-width: 70%;
                }
            }
        }
    .time {
        position: relative;
        &-start {
            font-weight: 900;
            font-size: 20px;
            color: #1A2744;
            display: block;
        }
        &-finish {
            font-weight: normal;
            font-size: 14px;
            color: #1A2744;
        }
        &:after {
            position: absolute;
            content: '';
            top: 15px;
            left: -26px;
            width: 16.98px;
            height: 16.67px;
            background: url(../assets/images/clock.svg) no-repeat center center;
            background-size: contain;
        }
    }
    @media screen and (max-width: 950px) {
        .calendar {
            &-event {
                &_img{
                    width: 100%;
                    float: none;
                }
            }
        }

    }
    @media screen and (max-width: 750px) {
        .event {
            &-info {
                display: flex;
                flex-direction: column;
                align-items: center;
                &_item {
                    margin: 2px 0 !important;
                    width: 60px;
                }
            }
        }
    }

    .category-icon {
        height: 29px;
        width: 48px;
        border-radius: 12px;
        margin-right: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rightimg {
        position: relative;
        margin: 0 0 20px 20px;
    }
    .btn {
        display: block;
        margin-top: 26px;
    }
    .vc-container {
        width: 100%;
        background: transparent;
        border: none;
        color: #fff !important;
    }
    .vc-pane {
        max-width: 100%;
    }
    .vc-title {
        color: #fff !important;
    }
    .vc-focusable {
        background: #ffc900 !important;
    }
    .vc-header {
        color: #fff !important;
        border-bottom: 2px solid #ddd;
    }

    .event-common {
        @media (max-width: 980px) {
            width: 100%;
            height: auto;

            .event-wrapper__slot {
                display: flex;

                .calendar-filter {
                    width: 50%;
                    margin-left: 40px;
                    margin-top: 15px;

                    @media (max-width: 700px) {
                        width: 100%;
                        margin-left: 0;
                    }
                }

                @media (max-width: 700px) {
                    display: block;
                }
            }
        }
    }
</style>



<style >
.highlight {
  background: #ffc900!important;
}
.vc-title {
  color: #fff !important;
}

.vc-day-layer span {
  background: #ffc900 !important;
}
.vc-arrow {
  border-radius: 50%;
  color: #fff;
}
.vc-arrow:hover {
  background: #ffc900 !important;
}
.vc-title {
  text-transform: capitalize;
}
.vc-header {
  color: #fff !important;
}
</style>
